import { useEffect, useRef,useState  } from 'react';
import { Col,  Row ,Button , Layout,Select,Typography ,Space,notification} from 'antd';
import {  SwapOutlined } from '@ant-design/icons';
import { useAccount } from 'wagmi'
import axios from 'axios';
import {isMobile} from 'react-device-detect';
import { useSignMessage } from 'wagmi'

    
const { Text } = Typography;

  function Withdraw({parentCallBack}){

    const [inputStyleFin, setInputStyleFin] = useState({textAlign:"start"});
    const [inputStyleMatic, setInputStyleMatic] = useState({textAlign:"end"});
   
    const [finValue, setFinValue] = useState();
    const [ethValue, setEthValue] = useState();
    const { address,  isConnected } = useAccount();
    const [user,setUser] = useState();

    const [tokenBalance, setTokenBalance] = useState();
    const [withdrawData, setWithdrawData] = useState();

    const [api, contextHolder] = notification.useNotification();
   
    const { signMessage, isSuccess } = useSignMessage()

    
    const [LoadingScreen, setLoadingScreen] = useState(false);
    var apikey = process.env.REACT_APP_API_KEY;





const getUser =async()=>{
  await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${address && address.toLowerCase()}`).then(async(res)=>{
          
  console.log(res);
      setUser(res.data);
     
      if(res.data == ""){
      
        
     openNotification('bottomLeft',"User not found","error")
   

       }

                  
      });
    }


useEffect(()=>{

  const getBalance = async()=>{

    getUser();
    await  axios.get(`https://mint.infinityvoid.io/gameApi/withdrawToken/byWalletAddress/${address && address.toLowerCase()}`)
    .then(async(res) => {
    

      if(res.data !== ""){

        setWithdrawData(res.data);

        var balance = Number(res.data.balance)
       setTokenBalance(balance);
     
      if(balance < 1){
        /*
        openNotification("bottom","0 Balance","error")
      */
      }
      else{
        /*
        openNotification("bottom",`you have ${balance} token`, "info")
      */
      }


      }else{
        /*
        openNotification("bottom","Please deposit First","error")
 */    
      }

    })

  }

  getBalance()
},[isConnected])




useEffect(()=>{
  if(isSuccess == true){
    withdrawApprove()

  }

},[isSuccess])

const openNotification = (placement,message,type) => {


  api.open({
    type:type,
    message: message,
    description:'',
    placement
  });
};


const withdrawApprove = async()=>{

    var balance = tokenBalance - finValue;
    balance.toString();
    
    
    var updateItem={
      balance:balance,
      apikey:apikey
    }
            await  axios.put(`https://mint.infinityvoid.io/gameApi/withdrawToken/${withdrawData.id}`,updateItem)
            .then(async(res) => {
      
      console.log(res.status);
      if(res.status == 200){
    
        var newitem = {
          data:{
            walletAddress:address && address.toLowerCase(),
            userId:user.id,
            token:"FINTOKEN",
            amount:finValue,
            status:"pending",
            apikey:apikey,
            transactionType:"Withdraw"
          }
      }
    
        await axios.post(`https://mint.infinityvoid.io/gameApi/depositToken`,newitem).then(async(res)=>{
    
        if(res.status == 200){
          openNotification("bottom","Withdraw Request Sent","success");
    
          window.location.reload(true);
        }
    
        })
       
    
      }
      
            })
    
          

}

    const WithDrawToken =async()=>{

      if(ethValue !== undefined && withdrawData !== undefined){

        var signMessageR = "Withdrawal " + finValue + " Token"

       const signResult =  signMessage({ message: signMessageR })

       console.log(signResult);



    }
 
  }

  

    const finChange = (event) => {

      if(event.target.value <= tokenBalance ){
        var value = event.target.value;
        setFinValue(value);
        var ethValue = 0.0006*value;
        setEthValue(ethValue.toFixed(3).toString());

        var NewWidth  = 100+value.toString().length*10;
        var NewWidthPx  = NewWidth.toString()+"px";
  
        if(NewWidth<300){
            setInputStyleFin({width:NewWidthPx});
        }else{
            setInputStyleFin({width:"300px"});
  
        }

        var NewfontSize  = 42-value.length*1.5;
        var NewfontSizePx  = NewfontSize.toString()+"px";

        var NewWidth  = 100+value.length*10;
        var NewWidthPx  = NewWidth.toString()+"px";

        if(NewWidth<300){
            setInputStyleMatic({width:NewWidthPx, textAlign:"end"});
        }else{
            setInputStyleMatic({width:"300px"});

        }
      }else{
        openNotification("bottom","Amount not available","error")
      }
      };  

    return(
        <>
        {contextHolder}

       
     
         <Row  gutter={24} style={{marginTop:"10%",marginBottom:"10%"}}>
    <Col className="maticCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }} >
        
        <Space direction='vertical' size={"middle"}>
      <Text className='textParaHead' >You'll withdraw</Text>
      <Space direction='horizontal'>
      <Select
     disabled
     showArrow={false}
      labelInValue
      bordered={false}
      defaultValue={{
      value: 'FINITY',
      label: 'TOKEN',
      }}

  />


<input onChange={finChange} className='Input' placeholder="0.00" value={finValue} style={inputStyleMatic}/>


</Space>


<Space direction='horizontal'>
  
<Text className='textPara'>1 TOKEN =</Text>    
 <input onChange={finChange}  className='InputA' disabled placeholder='0.0006' style={{width:"65px", padding:"8px"}}/>
 


</Space>


      </Space>
      </Col>

     
      <Col className='midCol' flex={{ xs: 4, sm: 4, md: 4, lg: 4 }} style={{textAlign:"center"}} >
      {isMobile?(
          <SwapOutlined rotate={270} onClick={()=>parentCallBack("changeTab")}  className='swapIcon' color='#ffffff'/>

        ):(
<SwapOutlined  onClick={()=>parentCallBack("changeTab")}  className='swapIcon' color='#ffffff'/>

        )}
              </Col>

      <Col className="finCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }} >
      <Space direction='vertical' size={"middle"}> 
      <Text className='textParaHead'> You'll receive *</Text>

      <Space direction='horizontal' size={'small'}>

      <input disabled className='Input' placeholder="0.00" value={ethValue} style={inputStyleFin}/>

      <Select
     
      disabled={true}
      showArrow={false}
      bordered={false}
    labelInValue
    defaultValue={{
      value: 'ETH',
      label: 'ETH',
    }}
    
    
  />



</Space>

<Space direction='horizontal'>
  
<Text className='textPara'>1 ETH =</Text>    
 <input onChange={finChange}  className='InputA' disabled placeholder="1500 TOKEN" style={{width:"120px", padding:"8px"}}/>
 


</Space>

       </Space>
      
      </Col>
    </Row>


<Button onClick={()=>WithDrawToken()}  style={{height:"40px", borderRadius:"20px"}}>Withdraw Amount</Button>


<br/>

<Text className='textPara' style={{fontSize:"12px",textAlign:"center"}}>*Transaction fees of 2.5%.</Text> 

        </>
    )
  }


    
export default Withdraw;