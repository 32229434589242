import {  ConfigProvider } from 'antd';
import { Routes, Route} from "react-router-dom";
import Exchange from "./pages/exchangePage";

import { WagmiConfig, createConfig, configureChains} from 'wagmi'
import { polygonMumbai } from 'wagmi/chains'
import { mainnet, polygon } from 'wagmi/chains'

import { useConnect,useAccount,useContract,useSigner ,useDisconnect  } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { Buffer } from "buffer";
import { Alchemy, Network } from "alchemy-sdk";
import { AlignLeftOutlined, BorderOutlined, CloseOutlined } from  '@ant-design/icons'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import "./App.css";
import AdminPage from './pages/AdminPage';


const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.MATIC_MAINNET,
};
const alchemy = new Alchemy(config);



const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, 
    polygon,
    polygonMumbai 
  ],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY }), publicProvider()],
)



Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;


const connectors= [
new MetaMaskConnector({ chains,   
 options: {
  appName: 'Metamask',
  appSub: 'Using your browser extension',
  imageIcon:'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png'
}, }),

new WalletConnectConnector({
  chains,
  options: {
    projectId: '23cc5d757cd05a82e2e1aeab51c8ef73',
    appName: 'Wallet Connect',
    appSub: 'Using a mobile wallet',
    imageIcon:'https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png'
  },
}),

new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'Coinbase Wallet',
    appSub: 'Using your mobile or browser extension',
    imageIcon:'https://cdn6.aptoide.com/imgs/0/b/6/0b619167f601e6355364c4f63f0ca166_icon.png'
  },
})
];

const clientWagmi = createConfig({
  //autoConnect: true,
 autoConnect: true,
  connectors:connectors,
  publicClient,
  webSocketPublicClient,
})


function App() {
  return ( 
    

<ConfigProvider

            theme={{
                token: {
                    fontFamily: 'proxima_nova_rgregular',
                    colorPrimary:'#26F8FF',
                   

               

                },
                components: {
                  Tabs: {
                    itemActiveColor:'rgb(43, 113, 255)',
                    itemSelectedColor:'rgb(43, 113, 255)',
                    itemHoverColor:'rgb(43, 113, 255)',
                    titleFontSize:'18px',
                    inkBarColor:"black"
                  },
                  Button:{
                    defaultHoverColor:"#000"
                  }
                },
            }}
        >
          <WagmiConfig  config={clientWagmi}>
  <Routes>
  
  
  <Route path="/" element={<Exchange />}/>

  <Route path="/admin" element={<AdminPage />}/>
 

  

</Routes>
</WagmiConfig>
  </ConfigProvider>

  )
}

export default App;
