import { useEffect, useState,  } from 'react';
import { Col, Modal,  Row ,Button , Layout,Typography , Space,Spin,notification, message} from 'antd';
import { useAccount,useDisconnect, useConnect} from 'wagmi'
import { Profile } from './profile';
import { CloseOutlined } from  '@ant-design/icons'

import { Tabs } from 'antd';
import { Alchemy, Network } from "alchemy-sdk";
import { LoadingOutlined } from '@ant-design/icons';
import "./home.css";
import Deposit from '../components/Deposit';
import Withdraw from '../components/Withdraw';
import TransactionHistory from '../components/TransactinHistory';
import axios from 'axios';
import {isMobile} from 'react-device-detect';

const { Header, Footer, Sider, Content } = Layout;
const { Text, Link } = Typography;

let randomvalue = Math.random();

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color:"#0A0519"
      
    }}
    spin
  />
);

const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: '100%',
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#fff',
  };

  const contentStyle = {
    minHeight: '70vh',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
    
    };



  const footerStyle = {
    height: '12vh',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#fff',
  };

  const config = {
    apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
    network: Network.MATIC_MAINNET,
  };
  const alchemy = new Alchemy(config);

function ExchangePage() {
  const { address, isConnected,  connector: activeConnector, } = useAccount();
    const { connect, connectors, error, isLoading, pendingConnector } =useConnect()
    const [tokenBalance, setTokenBalance] = useState();

    const { disconnect } = useDisconnect();
    const [LoadingScreen, setLoadingScreen] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    const [showWalletModal, setShowWalletModal] = useState(false);

    const [activePage, setActivePage] = useState();
    const [activeTab, setActiveTab] = useState(true);

const connectWallet = async()=>{
setShowWalletModal(true);
}

const disconnectWallet = async()=>{
  disconnect();

  }


  

  useEffect(()=>{

    const getBalance = async()=>{
        await  axios.get(`https://mint.infinityvoid.io/gameApi/withdrawToken/byWalletAddress/${address && address.toLowerCase()}`)
      .then(async(res) => {

        var bal = Number(res.data.balance);
      
      
  
        if(res.data !== ""){
          if(Number.isInteger(bal)){
        
           
            setTokenBalance(bal);
          }else{

            var balance = Number(res.data.balance).toFixed(3)
            setTokenBalance(balance);
          }
       
       
    }else{
      setTokenBalance(0);
    }})
    }
    getBalance()
  },[isConnected])
  

  useEffect(()=>{
    console.log(address);
    if(address !== undefined){
     setShowWalletModal(false);

    }

},[isConnected]);


const handleDepositData = (message)=>{


  if(message == "changeTab"){

    console.log("ChangeTab")
   setActiveTab(!activeTab);
    }


if(message == "startLoading"){
setLoadingScreen(true);
}

if(message == "stopLoading"){
  setLoadingScreen(false);
  }

  if(message == "openLoginModal"){
    setShowWalletModal(true);
    }


}

  

      

  return (


<>

{contextHolder}

<Spin style={{marginTop:'15%', marginBottom:'15%'}} spinning={LoadingScreen} indicator={antIcon} delay={14} size={'large'}>

     <Modal 
     
     
       closeIcon={<CloseOutlined style={{color:"#26F8FF"}}/>}
        open={showWalletModal}
        centered
        onOk={() => setShowWalletModal(false)}
        onCancel={() => setShowWalletModal(false)}
        footer={null}
        mask={false}
        zIndex={10}
         >
        <Profile/>
      </Modal>




<Layout>
      <Header style={headerStyle}>

      {!isMobile?(
          <>
      <Row >

    
      <Col flex={2} className="navBarLogo">

        <Space.Compact direction='horizontal'>
          
          <img src = "./logoicon.webp" style={{width:"64px"}}/>
<Text style={{fontFamily:"Audiowide", fontSize:"24px",marginLeft:"8px",marginTop:"5px", fontWeight:"200px"}}>Infinity Void</Text>
</Space.Compact>
      </Col>
      <Col flex={2} className="navBarButton">

        {isConnected == true?(

          <Space direction='horizontal'>

     
{activePage == "transaction"?(<>
  <Button type='link' className="textWallet" onClick={()=>setActivePage('')}>
             Back
          </Button>
        </>):(<>
          <Button type='link' className="textWallet" onClick={()=>setActivePage('transaction')}>
             Transaction 
          </Button>
        </>)}
  

          <Button className="textWallet">
              Balance - {tokenBalance} TOKEN 
          </Button>


          <Text className="textWallet">
              {address.substring(0, 4)}....{address.slice(38)} 
          </Text>

<Button  onClick={()=>disconnectWallet()} style={{height:"40px", borderRadius:"20px"}}>Disconnect</Button>
</Space>


        ):(
<Button  onClick={()=>connectWallet()} style={{height:"40px", borderRadius:"20px"}}>Connect</Button>

        )}
      </Col>

    </Row>
    </>):(<>
    

      <Row >

    
<Col flex={2} className="navBarLogo">

  <Space.Compact direction='horizontal'>
    
    <img src = "./logoicon.webp" style={{width:"64px"}}/>
    <Text style={{fontFamily:"Audiowide", fontSize:"24px",marginLeft:"8px",marginTop:"5px", fontWeight:"200px"}}>Infinity Void</Text>

</Space.Compact>
</Col>
<Col flex={2} className="navBarButton">

  {isConnected == true?(

    <Space direction='horizontal'>



<Button  onClick={()=>disconnectWallet()} style={{height:"40px", borderRadius:"20px"}}>Disconnect</Button>
</Space>


  ):(
<Button  onClick={()=>connectWallet()} style={{height:"40px", borderRadius:"20px"}}>Connect</Button>

  )}
</Col>

</Row>

<div >


  {isConnected == true?(

    <Space direction='horizontal'>

{activePage == "transaction"?(<>
  <Button type='link' onClick={()=>setActivePage('')}>
             Back
          </Button>
        </>):(<>
          <Button type='link'  onClick={()=>setActivePage('transaction')}>
             Transaction 
          </Button>
        </>)}
        <Button >
        Balance - {tokenBalance} TOKEN 
          </Button>
      
</Space>):(null)}


  
</div>
    
    </>)}
      </Header> 


      <Content style={{backgroundColor:"#fff"}}>
        {activePage == "transaction"?(<div style={{marginTop:"2%"}}>
          <TransactionHistory loadNew={randomvalue}/>
        </div>):(<>
       
          <Content className='contentStyleMain'>
     
     <Content className='contentStyleMainChild'>
{activeTab == true?(
          <Deposit parentCallBack={handleDepositData}/>


):(

  <Withdraw parentCallBack={handleDepositData}/>


)}
     </Content>
     </Content>

        </>)}
      
     
        </Content>
       
      <Footer style={footerStyle}>


      </Footer>
    </Layout>

    </Spin>

</>

  );
}

export default ExchangePage;
