import { useState,useEffect  } from 'react';
import { Col,  Row ,Button ,Table,Tag, Layout,Select,Typography ,Space,notification} from 'antd';
import { useConnect,useAccount,useBalance , useContractWrite,usePrepareContractWrite,  useDisconnect, } from 'wagmi'
import ethAbi from './ethAbi.json';
import Web3 from 'web3';
import { useNetwork, useSwitchNetwork } from 'wagmi'
import axios from 'axios';
import { parseEther } from 'viem'


function Admin({parentCallBack}){
  var apikey = process.env.REACT_APP_API_KEY;
  const { address,  isConnected } = useAccount();
  const[historyData,setHistoryData] = useState();
  const [api, contextHolder] = notification.useNotification();
  const { chain } = useNetwork()
  const EthAddress = "0x9E7e513962d064622b5153F84d8BE5D5cF4E11EC";
  var web3 = new Web3(window.ethereum)


  useEffect(()=>{

  
    const getData = async()=>{
  
    
      await  axios.get("https://mint.infinityvoid.io/gameApi/depositToken")
      .then(async(res) => {
      
       
  console.log(res.data);
       
         

var newdata = res.data.rows.filter(e=>e.transactionType == "Withdraw").map((item,index)=>{
return(
 

  {
    key: index+1,
    sno: index+1,
    address:item.walletAddress,
    amount:item.amount,
    traid: item.transactionId,
    status:item.status,
    thash:item.transactionHash,
    id:item.id

  }
  

  
)
})

setHistoryData(newdata);
  
      
  
      })
  
  }
  
    getData()
  },[isConnected])
  
  const contractETH = new web3.eth.Contract(
    ethAbi,
   EthAddress
 )

 const ConfirmFun =async(data)=>{

  var trId =  Number(data.traid);


  await contractETH.methods.confirmTransaction(trId).send({from:address}).then(function(result){


    parentCallBack("startLoading")

    if(result.status == true){

      var newitem = {
        status:"success",
        transactionHash:result.transactionHash,
        apikey:apikey
     
    }
    
    
    axios.put(`https://mint.infinityvoid.io/gameApi/depositToken/${data.id}`, newitem).then((res) => {
    console.log(res);
    if(res.status == 200){
    window.location.reload();
    
    openNotification("bottom","Confirm Done","success");
    
      }
    
      })
    }})

 

 }

  const ApproveFun =async(data)=>{


var amount = Number(data.amount)/1500;
var bal = await contractETH.methods.getAllTransaction().call();


var _to = data.address;
var _amount = amount.toFixed(3).toString();

var valueTosend = web3.utils.toWei(_amount,'ether');

await contractETH.methods.withdrawTransaction(_to,valueTosend).send({from:address}).then(function(result){
    
  parentCallBack("startLoading")


if(result.status == true){

  var newitem = {
    transactionId:bal,
    transactionHash:result.transactionHash,
    apikey:apikey
 
}


axios.put(`https://mint.infinityvoid.io/gameApi/depositToken/${data.id}`, newitem).then((res) => {
console.log(res);
if(res.status == 200){
window.location.reload();

openNotification("bottom","Approve Done","info");

  }

  })
}})


  }

  
const openNotification = (placement,message,type) => {


  api.open({
    type:type,
    message: message,
    description:'',
    placement
  });
};


    const columns = [
        {
          title: 'S.NO.',
          dataIndex: 'sno',
          key: 'sno',
        },
        {
          title: 'Owner',
          dataIndex: 'address',
          key: 'address',
        },
         {
            title: 'Amount in ETH',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount) => <>
            
            {(Number(amount)/1500).toFixed(3)} 
            
                        </>,
        },


        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <>
{status == "pending"?(<Tag color="red">{status}</Tag>):(<Tag color="lime">{status}</Tag>)}


            </>,
        },

        {
          title: 'Transaction Id',
          dataIndex: 'traid',
          key: 'traid',
      },

        {
            title: 'Transaction Hash',
            dataIndex: 'thash',
            key: 'thash',
            render:(thash) =>(
              <a  href= {`https://sepolia.etherscan.io/tx/${thash}`} target="_blank">
{thash && thash.substring(0, 4)}....{thash && thash.slice(60)} 
</a>
            )
        },

        {
          title: 'Action',
          key: 'action',
          render: (record) => (
            <Space size="middle">
              {
              record.status == "pending"?(
            <>
{record.traid == undefined ?(
             <Button onClick={()=>ApproveFun(record)}>Approve</Button>

):(             <Button onClick={()=>ConfirmFun(record)}>Confirm</Button>
)}
</>):(<Button disabled>Confirmed</Button>)
        }
            </Space>
          ),
        },

      ];


console.log(historyData);

   return(
    <>
    <div style={{margin:"2%"}}>
    <Table  columns={columns} dataSource={historyData} />
    </div>
    </>
   ) 

}
export default Admin