import { useState,useEffect  } from 'react';
import {Table,Tag,notification} from 'antd';
import axios from 'axios';
import { useAccount} from 'wagmi'
var moment = require('moment');

function TransactionHistory({loadNew}){
  var apikey = process.env.REACT_APP_API_KEY;
  const { address,  isConnected } = useAccount();
  const[historyData,setHistoryData] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [initLoad,setInitLoad] = useState(false);


  useEffect(()=>{

  
    const getData = async()=>{
  
    
      await  axios.get(`https://mint.infinityvoid.io/gameApi/depositToken/byWalletAddress/${address && address.toLowerCase()}`)
      .then(async(res) => {
      
       
  
        if(res.data !== ""){
         

var newdata = res.data.reverse().map((item,index)=>{
return(
 

  {
    key: index+1,
    sno: index+1,
    date:moment(item.createdAt).format("DD-MM-YYYY: HH:mm:ss"),
    ttype: item.transactionType,
    traid: item.id,
    token:item.token,
    amount:item.amount,
    status:item.status,
    thash:item

  }
  

  
)
})

setHistoryData(newdata);
  
        }else{
          /*
          openNotification("bottom","Please deposit First","error")
        */
        }
  
      })
  
  }
  
    getData()
  },[isConnected,loadNew])
  

  
const openNotification = (placement,message,type) => {


  api.open({
    type:type,
    message: message,
    description:'',
    placement
  });
};


    const columns = [
        {
          title: 'S.NO.',
          dataIndex: 'sno',
          key: 'sno',
          width: 150,
         
          
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          width: 150,
         
          
        },
        {
          title: 'Transaction Type',
          dataIndex: 'ttype',
          key: 'ttype',
          width: 150,
          render: (ttype) => <>
          {ttype == "Withdraw"?(<>Withdrawal</>):(<>{ttype}</>)}
          </>
          
        },
       
        {
            title: 'Token',
            dataIndex: 'token',
            key: 'token',
            width: 150,
            render: (token) => <>
            {token == "FINTOKEN"?(<>TOKEN</>):(<>{token}</>)}
            </>
          
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
          
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render: (status) => <>
{status == "pending"?(<Tag color="red">{status}</Tag>):(<Tag color="lime">{status}</Tag>)}


            </>,
        },

        {
          title: 'Transaction Id',
          dataIndex: 'traid',
          key: 'traid',
          width: 150,
          
      },

        {
            title: 'Transaction Hash',
            dataIndex: 'thash',
            key: 'thash',
            width: 150,
            render: (record) => <>
{record.transactionHash !== undefined?(
  <>

{record.transactionType == "Withdraw"?(<a  href= {`https://sepolia.etherscan.io/tx/${record.transactionHash}`} target="_blank">
{record.transactionHash && record.transactionHash.substring(0, 4)}....{record.transactionHash && record.transactionHash.slice(60)} 
</a>):(
  
<>
{record.transactionType == "Deposit" && record.token == "USDTETH" || record.token == "ETH" ?(
  <>
  <a href= {`https://sepolia.etherscan.io/tx/${record.transactionHash}`} target="_blank">
  {record.transactionHash && record.transactionHash.substring(0, 4)}....{record.transactionHash && record.transactionHash.slice(60)} 
</a>
  </>
):(
  <>
  <a href= {`https://mumbai.polygonscan.io/tx/${record.transactionHash}`} target="_blank">
  {record.transactionHash && record.transactionHash.substring(0, 4)}....{record.transactionHash && record.transactionHash.slice(60)} 
</a>
  </>
)}
</>

)}
</>
):(null)

}
</>
           
        },

      ];


console.log(historyData);

   return(
    <>
    <div style={{margin:"2%"}}>
    <Table  scroll={{ x: 1200 }} columns={columns} dataSource={historyData} />
    </div>
    </>
   ) 

}
export default TransactionHistory