import { useState ,useEffect } from 'react';
import { Col,  Row ,Button ,Spin, Layout,Select,Typography ,Space,notification} from 'antd';
import {  SwapOutlined , LoadingOutlined} from '@ant-design/icons';
import Web3 from 'web3';
import { useNetwork, useSwitchNetwork } from 'wagmi'
import axios from 'axios';
import ethAbi from './ethAbi.json';
import maticAbi from './maticAbi.json';
import usdtAbi from './usdc-abi.json';
import { useAccount,useBalance  } from 'wagmi'
import { parseEther } from 'viem'
import {isMobile} from 'react-device-detect';

    
const { Text } = Typography;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color:"#0A0519"
      
    }}
    spin
  />
);

  function Deposit({parentCallBack}){

    const { chain } = useNetwork()
    const [inputStyleFin, setInputStyleFin] = useState({textAlign:"start"});
    const [inputStyleMatic, setInputStyleMatic] = useState({textAlign:"end"});
    const [selectedCoin, setSelectedCoin] = useState("ETH");
    const [selectedCoinRate, setSelectedCoinRate] = useState("1500 TOKEN");
    const [selectedCoinRateFinity, setSelectedCoinRateFinity] = useState("0.0006 ETH");
    const [selectedCoinValue, setSelectedCoinValue] = useState();
    const [finValue, setFinValue] = useState();

 const [user,setUser] = useState();

    const[userBalance,setUserBalance]=useState(false);
    const[depositData,setDepositData]=useState();
 const [userNotFound ,setUserNotFound]=useState(true);
    var apikey = process.env.REACT_APP_API_KEY;
 
    const usdtEthAddress = "0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238";
    const usdtMaticAddress = "0x9999f7Fea5938fD3b1E26A12c3f2fb024e194f97";
    const EthAddress = "0x9E7e513962d064622b5153F84d8BE5D5cF4E11EC";
    const MaticAddress = "0x23B0A5E1E034ccdd76a123Bf2801E98231FcCb49";
    const[contractAddress,setContractAddress]=useState();
    const[functionName,setfuntionName]=useState();

    var web3 = new Web3(window.ethereum)
    const { chains, error,  pendingChainId, switchNetwork } =
    useSwitchNetwork()

    const { address,  isConnected } = useAccount();
    console.log(address);

    const [api, contextHolder] = notification.useNotification();
  
    
    const [LoadingScreen, setLoadingScreen] = useState(true);
  

const CryptoCall = async(data)=>{

  console.log(data);

  var valueTosend = web3.utils.toWei(selectedCoinValue,'ether');

  if(selectedCoin == "ETH"){

    var contractCall = new web3.eth.Contract(
      ethAbi,
     EthAddress
   )
   
  
   await contractCall.methods.depositTransaction().send({from:address,value:valueTosend}).then(function(result){
    
      console.log(result);
  if(result.status == true){


    var newData ={
      walletAddress:address && address.toLowerCase(),
      userId:user.id,
      token:selectedCoin,
      amount:selectedCoinValue,
      status:"success",
      apikey:apikey,
      transactionHash:result.transactionHash,
      transactionType:"Deposit"
     }

saveData(newData)

  }

     }).catch (function (error){
    
      var message = error.data && error.data.message ;
      console.log(error);
    
    });
    }

    if(selectedCoin == "MATIC"){

      var valueTosend = web3.utils.toWei(selectedCoinValue,'ether');

      var contractCall = new web3.eth.Contract(
        maticAbi,
       MaticAddress
     )
     
    
     await contractCall.methods.depositTransaction().send({from:address,value:valueTosend}).then(function(result){
      
        console.log(result);

        if(result.status == true){


          var newData ={
            walletAddress:address && address.toLowerCase(),
            userId:user.id,
            token:selectedCoin,
            amount:selectedCoinValue,
            status:"success",
            apikey:apikey,
            transactionHash:result.transactionHash,
            transactionType:"Deposit"
           }
      
      saveData(newData)
      
        }
    
       }).catch (function (error){
      
        var message = error.data && error.data.message ;
        console.log(error);
      
      });
      }

      if(selectedCoin == "USDTETH"){

        var contractCall = new web3.eth.Contract(
          ethAbi,
         EthAddress
       )
       
      
       await contractCall.methods.depositUSDTTransaction(data).send({from:address}).then(function(result){
        
          console.log(result);

          if(result.status == true){


            var newData ={
              walletAddress:address && address.toLowerCase(),
              userId:user.id,
              token:selectedCoin,
              amount:selectedCoinValue,
              status:"success",
              apikey:apikey,
              transactionHash:result.transactionHash,
              transactionType:"Deposit"
             }
        
        saveData(newData)
        
          }
      
         }).catch (function (error){
        
          var message = error.data && error.data.message ;
          console.log(error);
        
        });
        }

        if(selectedCoin == "USDTMATIC"){

          var contractCall = new web3.eth.Contract(
            maticAbi,
           MaticAddress
         )
         
        
         await contractCall.methods.depositUSDTTransaction(data).send({from:address}).then(function(result){
          
            console.log(result);

            if(result.status == true){


              var newData ={
                walletAddress:address && address.toLowerCase(),
                userId:user.id,
                token:selectedCoin,
                amount:selectedCoinValue,
                status:"success",
                apikey:apikey,
                transactionHash:result.transactionHash,
                transactionType:"Deposit"
               }
          
          saveData(newData)
          
            }
        
           }).catch (function (error){
          
            var message = error.data && error.data.message ;
            console.log(error);
          
          });
          }

}



    const saveData = async(data)=>{

      var newitem = {
        data:data
    }
    
      await axios.post(`https://mint.infinityvoid.io/gameApi/depositToken`,newitem).then(async(res)=>{

      if(res.status == 200){

       var coinBalData =  await axios.get(`https://mint.infinityvoid.io/gameApi/withdrawToken/byUserId/${user.id}`).then(async(res)=>{

       if(res.data !== ""){



var balance = Number(res.data.balance) + Number(finValue);
balance.toString();


        var withdrawData ={
         balance:balance,
         apikey:apikey
         }

        await axios.put(`https://mint.infinityvoid.io/gameApi/withdrawToken/${res.data.id}`,withdrawData).then((res)=>{
 
 
        if(res.status == 200){

          openNotification("bottom","Deposited Successfully","success");
          window.location.reload(true);
        }else{
          openNotification("bottom","Deposited Failed","error");
        }

        })


       }else{

        var withdrawData ={
          data:{
          userId:user.id,
          walletAddress:address && address.toLowerCase(),
          balance:finValue,
          apikey:apikey
          }
          }

        await axios.post(`https://mint.infinityvoid.io/gameApi/withdrawToken`,withdrawData).then((res)=>{
          if(res.status == 200){

            openNotification("bottom","Deposited Successfully","success");
            window.location.reload(true);

          }else{
            openNotification("bottom","Deposited Failed","error");
          }
        
                  })

       }

       })


       

      }
    })
    }
  
     const balance = useBalance({
      address: address,
    
    })

    useEffect(()=>{

  
      const  getUserData =async()=>{
      
        if(isConnected == true){
      await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${address && address.toLowerCase()}`).then(async(res)=>{
          
      console.log(res);
          setUser(res.data);
          setUserNotFound(false);
          if(res.data == ""){
            setUserNotFound(true);
      
            
         openNotification('bottomLeft',"User not found","error")
       

           }

                      
          });
        }
      
      }
      
          getUserData()
      },[address, isConnected])
  
  

    const openNotification = (placement,message,type) => {


      api.open({
        type:type,
        message: message,
        description:'',
        placement
      });
    };


 



    const handleChange = (value) => {
        setSelectedCoin(value.key);
        if(value.key == "ETH"){
        setSelectedCoinRate("1500 TOKEN");
        setSelectedCoinRateFinity("0.0006 ETH");
        var finValue = 1500*selectedCoinValue;
        if(selectedCoinValue > 0){
          setFinValue(finValue.toString());
        }
      }

        if(value.key == "USDTETH"){
          setSelectedCoinRate("1 TOKEN");
          setSelectedCoinRateFinity("1 USDTETH");
          var finValue = 1*selectedCoinValue;
          if(selectedCoinValue > 0){
            setFinValue(finValue.toString());
          }

        }
        if(value.key == "USDTMATIC"){
          setSelectedCoinRate("1 TOKEN");
          setSelectedCoinRateFinity("1 USDTMATIC");
          var finValue = 1*selectedCoinValue;
          if(selectedCoinValue > 0){
            setFinValue(finValue.toString());
          }
         
        }

        if(value.key == "MATIC"){
          setSelectedCoinRate("1000 TOKEN");
          setSelectedCoinRateFinity("0.001 MATIC");
          var finValue = 1000*selectedCoinValue;
          if(selectedCoinValue > 0){
            setFinValue(finValue.toString());
          }
         
        }
        
      };

    const maticChange = (event) => {
        var value = event.target.value;
        setSelectedCoinValue(value);
        if(selectedCoin == "MATIC"){
          var finValue = 1000*value;
          setFinValue(finValue.toString());
          var NewWidth  = 100+finValue.toString().length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px"});

      }
        }
        
        if(selectedCoin == "ETH"){
          var finValue = 1500*value;
          setFinValue(finValue.toString());
          var NewWidth  = 100+finValue.toString().length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px"});

      }
        }


        if(selectedCoin == "USDTETH"){
          var finValue = 1*value;
          setFinValue(finValue.toString());
          var NewWidth  = 100+finValue.toString().length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px"});

      }
        }

        if(selectedCoin == "USDTMATIC"){
          var finValue = 1*value;
          setFinValue(finValue.toString());
          var NewWidth  = 100+finValue.toString().length*10;
      var NewWidthPx  = NewWidth.toString()+"px";

      if(NewWidth<300){
          setInputStyleFin({width:NewWidthPx});
      }else{
          setInputStyleFin({width:"300px"});

      }
        }


        var NewfontSize  = 42-value.length*1.5;
        var NewfontSizePx  = NewfontSize.toString()+"px";

        var NewWidth  = 100+value.length*10;
        var NewWidthPx  = NewWidth.toString()+"px";

        if(NewWidth<300){
            setInputStyleMatic({width:NewWidthPx, textAlign:"end"});
        }else{
            setInputStyleMatic({width:"300px"});

        }
       
      };  



    
       const DepositToken =async()=>{
    
    if(isConnected){

        console.log(selectedCoinValue);
   
      if(selectedCoin == "ETH"){
        console.log("ETH")
    
    
       if(chain && chain.id == 11155111){
    
        setfuntionName('depositTransaction');
        
        setContractAddress(EthAddress);
    
CryptoCall(parseEther(selectedCoinValue))

      
    
        
       }else{
        switchNetwork(11155111);
       }
        
     
    
      }
      if(selectedCoin == "MATIC"){
        console.log("MATIC")
        
        if(chain && chain.id == 80001){
        setfuntionName('depositTransaction');
        setContractAddress(MaticAddress);
    
        CryptoCall(parseEther(selectedCoinValue))


      }else{
        switchNetwork(80001);
      }
      }
      if(selectedCoin == "USDTETH"){
    
        if(chain && chain.id == 11155111){
    
        console.log(address);
    
        setfuntionName('depositUSDTTransaction');
        setContractAddress(EthAddress);
        const usdtContractETH = new web3.eth.Contract(
          usdtAbi,
         usdtEthAddress
       )
    
     var tokenValue = Number(selectedCoinValue)*1000000; 
     parentCallBack("startLoading")

     

  
     const res =  usdtContractETH.methods.approve(EthAddress,tokenValue).send({from:address}).then(function(result){
      checkTransactionStatus(result,tokenValue);
     }).catch (function (error){
    
      var message = error.data && error.data.message ;
      console.log(error);
    
    });
   
    
        console.log("USDTETH")
        }else{
          switchNetwork(11155111);
        }
    
    }
      if(selectedCoin == "USDTMATIC"){
    
        if(chain && chain.id == 80001){
        console.log("USDTMATIC")
        setContractAddress(MaticAddress);
        setfuntionName('depositUSDTTransaction');
    
        const usdtContractMATIC = new web3.eth.Contract(
          usdtAbi,
         usdtMaticAddress
       )
    
       var tokenValue = Number(selectedCoinValue)*1000000; 
       parentCallBack("startLoading")
      CryptoCall(tokenValue);


      const res = await usdtContractMATIC.methods.approve(MaticAddress,tokenValue).send({from:address}).then(function(result){
    

        checkTransactionStatus(result,tokenValue);
       }).catch (function (error){
    
        var message = error.data && error.data.message ;
        console.log(error);
    
    });


        }else{
          switchNetwork(80001);
        }
      
      
      } 

 

    }else{
parentCallBack("openLoginModal")
    }
    
       }



       const  checkTransactionStatus=async(result,tokenValue)=>{
   
        console.log(result);
        
      
            
            const txReceipt = await web3.eth.getTransactionReceipt(
              result.transactionHash
             );
             
             if(txReceipt == null){
              setTimeout(checkTransactionStatus(result), 60);
             }else{
              if(txReceipt.status == 1){

                parentCallBack("stopLoading")

                CryptoCall(tokenValue)
        /*
                write({
                  from: address && address,
                  args:[tokenValue],
                })
        */
              }
             }
             
          
          }

    return(
        <>
        
{contextHolder}

     
         <Row  gutter={24} style={{marginTop:"10%",marginBottom:"10%"}}>
    <Col className="maticCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }} >
        
        <Space direction='vertical' size={"middle"}>
      <Text className='textParaHead' >You'll deposit</Text>
      <Space direction='horizontal'>
      <Select
     
      labelInValue
      bordered={false}
      defaultValue={{
      value: 'ETH',
      label: 'ETH',
      }}
    
    onChange={handleChange}
    options={[
      {
        value: 'ETH',
        label: 'ETH',
      },
      {
        value: 'MATIC',
        label: 'MATIC',
      },
 
    ]}
  />


<input onChange={maticChange} className='Input' placeholder="0.00" value={selectedCoinValue} style={inputStyleMatic}/>


</Space>


<Space direction='horizontal'>
  
<Text className='textPara'>1 {selectedCoin} =</Text>    
{selectedCoin == "MATIC"?(
 <input onChange={maticChange}  className='InputA' disabled placeholder={selectedCoinRate} style={{width:"115px", padding:"8px"}}/>
 ):(
<input onChange={maticChange}  className='InputA' disabled placeholder={selectedCoinRate} style={{width:"115px", padding:"8px"}}/>

)}


</Space>


      </Space>
      </Col>

     
      <Col className='midCol' flex={{ xs: 4, sm: 4, md: 4, lg: 4 }} style={{textAlign:"center"}} >
        {isMobile?(
          <SwapOutlined rotate={270} onClick={()=>parentCallBack("changeTab")}  className='swapIcon' color='#ffffff'/>

        ):(
<SwapOutlined  onClick={()=>parentCallBack("changeTab")}  className='swapIcon' color='#ffffff'/>

        )}
      </Col>

      <Col className="finCol" flex={{ xs: 32, sm: 32, md: 10, lg: 10 }} >
      <Space direction='vertical' size={"middle"}> 
      <Text className='textParaHead'> You'll receive </Text>

      <Space direction='horizontal' size={'small'}>

      <input disabled className='Input' placeholder="0.00" value={finValue} style={inputStyleFin}/>

      <Select
     
      disabled={true}
      showArrow={false}
      bordered={false}
    labelInValue
    defaultValue={{
      value: 'TOKEN',
      label: 'TOKEN',
    }}
    
    
  />



</Space>

<Space direction='horizontal'>
  
<Text className='textPara'>1 TOKEN =</Text>    
{selectedCoin == "MATIC"?(
  <input onChange={maticChange}  className='InputB' disabled placeholder={selectedCoinRateFinity} style={{width:"98px",padding:"8px"}}/>
):(
<input onChange={maticChange}  className='InputB' disabled placeholder={selectedCoinRateFinity} style={{width:"135px",padding:"8px"}}/>

)}


</Space>

       </Space>
      
      </Col>
    </Row>


<Button  onClick={()=>DepositToken()}  style={{height:"40px", borderRadius:"20px"}}>Deposit Amount</Button>
<br/>



        </>
    )
  }


    
export default Deposit;